import React, { useEffect, useState } from "react";
import { DatePickerView, PickerView, Image, Toast } from "antd-mobile";
import DatePicker from "../../components/Model/DatePicker";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";
import moment from "moment";
import Http from "../../utils/Http";
import { Spin } from "antd";

const now = new Date();
const btnDown = require("./assets/sys_btn_down@2x.png");

const WithdrawalList = () => {
  const [value, setValue] = useState(now);
  const [isShow, setIsShow] = useState(true);
  const navigate = useNavigate();
  const dateRef = React.useRef();
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [money, setMoney] = useState("");

  const getData = async (clean) => {
    if (loading) return;
    try {
      const res = await Http.postForm("/api/v1/wechat_public/withdrawRecord", {
        time: moment(value).format("YYYY-MM"),
        per: 10,
        page: clean ? 1 : page + 1,
      });
      if (res.status === 10000) {
        setPage(res.page);
        setHasNext(res.has_next);
        if (clean) {
          // res.data.push({
          //     created_at: '2023-09-25 20:00:00',
          //     id: 1,
          //     get_amount: '95',
          //     total_amount: '100'
          // })
          setData(res.data);
        } else {
          setData((old) => {
            return old.concat(data);
          });
        }
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getMoney = async () => {
    if (loading) return;
    try {
      const res = await Http.postForm("/api/v1/wechat_public/withdrawAmount", {
        time: moment(value).format("YYYY-MM"),
      });
      if (res.status === 10000) {
        setMoney(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // getData(true);
    // getMoney();
    tixianSum();
    getFan();
  }, []);

  // 音色
  const { state } = useLocation();
  const { withdrawNum, fanYong } = state;

  const [data, setData] = useState([]);
  // 获取我的提现记录和可提现数额
  const tixianSum = async () => {
    try {
      const res = await Http.postForm("/data/api.auth.transfer/get");
      if (res.code === 1) {
        if (!fanYong) {
          setData(res.data.list);
        }
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };
  // 返佣记录
  const getFan = async () => {
    try {
      // item:2 查询七天的冻结
      const res = await Http.postForm("/data/api.auth.transfer/rebate", {
        item: 2,
      });
      if (res.code === 1) {
        if (fanYong) {
          setData(res.data.list);
        }
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };
  return (
    <div className="withdrawallist-page">
      <DatePicker
        ref={dateRef}
        date={value}
        onChange={(date) => {
          setValue(date);
        }}
      />
      {fanYong ? (
        ""
      ) : (
        <div
          style={{
            textAlign: "left",
            paddingTop: 12,
            paddingBottom: 13,
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <div>
            <span>累计提现 ¥ {withdrawNum || 0}</span>
          </div>
        </div>
      )}
      <div
        style={{ height: 0.5, width: "100%", backgroundColor: "#F6F6F6" }}
      ></div>
      {fanYong ? (
        // 待到账金额
        <div className="withdrawallist">
          {data?.map((item) => (
            <div key={item.tx_no} className="withdrawallist-item">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                // style={{
                //   color:
                //     item.status === 0
                //       ? "#FE4447"
                //       : item.status === 1
                //       ? "#E9A900"
                //       : "#050303",
                // }}
                >
                  {/* <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {item.status === 1 && "待审核"}
                    {item.status === 2 && "已审核"}
                    {item.status === 3 && "打款中"}
                    {item.status === 4 && "已打款"}
                    {item.status === 5 && "已收款"}
                    {item.status === 0 && "已拒绝"}
                  </span>
                  <span style={{ fontSize: 15, fontWeight: 500 }}>-到</span>
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {item.type === "transfer_banks" && "银行卡"}
                  </span>
                  <span>({item.bank_code.slice(-6)})</span>
                  <br /> */}
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    单号:{item.order_no}
                  </span>
                </div>

                <span
                  style={{
                    fontSize: 12,
                    color: "#979797",
                  }}
                >
                  {moment(item.date).format("YYYY-MM-DD")}
                </span>
                <span
                  style={{
                    fontSize: 12,
                    color: "#979797",
                  }}
                >
                  解冻日期:{item.thaw_time}
                </span>
              </div>
              <div
                style={{
                  fontSize: 15,
                  // color:
                  //   // #FE4447  #E9A900  #050303
                  //   item.status === 0
                  //     ? "#FE4447"
                  //     : item.status === 1
                  //     ? "#E9A900"
                  //     : "#050303",
                  fontWeight: 600,
                }}
              >
                +{item.amount}
                {/* 到账：¥{" "}
                {(Number(item.amount) - Number(item.charge_amount)).toFixed(2)}
                <br />
                <span style={{ fontSize: 12, color: "black" }}>
                  提交金额: {item.amount}
                </span> */}
              </div>
            </div>
          ))}
          {!data.length ? (
            <div style={{ height: 100, lineHeight: 10 }}>暂无记录</div>
          ) : (
            <div>仅显示近100条提现记录</div>
          )}
        </div>
      ) : (
        <div className="withdrawallist">
          {data?.map((item) => (
            <div
              key={item.tx_no}
              className="withdrawallist-item"
              onClick={() => {
                navigate("/withdrawalDetail", { state: { item: item } });
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    color:
                      item.status === 0
                        ? "#FE4447"
                        : item.status === 1
                        ? "#E9A900"
                        : "#050303",
                  }}
                >
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {item.status === 1 && "待审核"}
                    {item.status === 2 && "已审核"}
                    {item.status === 3 && "打款中"}
                    {item.status === 4 && "已打款"}
                    {item.status === 5 && "已收款"}
                    {item.status === 0 && "已拒绝"}
                  </span>
                  <span style={{ fontSize: 15, fontWeight: 500 }}>-到</span>
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {item.type === "transfer_banks" && "银行卡"}
                  </span>
                  <span>({item.bank_code.slice(-6)})</span>
                  <br />
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    单号:{item.code}
                  </span>
                </div>

                <span
                  style={{
                    fontSize: 12,
                    color: "#979797",
                  }}
                >
                  {moment(item.date).format("YYYY-MM-DD")}
                </span>
              </div>
              <div
                style={{
                  fontSize: 15,
                  color:
                    // #FE4447  #E9A900  #050303
                    item.status === 0
                      ? "#FE4447"
                      : item.status === 1
                      ? "#E9A900"
                      : "#050303",
                  fontWeight: 600,
                }}
              >
                到账：¥{" "}
                {(Number(item.amount) - Number(item.charge_amount)).toFixed(2)}
                <br />
                <span style={{ fontSize: 12, color: "black" }}>
                  提交金额: {item.amount}
                </span>
              </div>
            </div>
          ))}
          {!data.length ? (
            <div style={{ height: 100, lineHeight: 10 }}>暂无记录</div>
          ) : (
            <div>仅显示近100条提现记录</div>
          )}
        </div>
      )}
    </div>
  );
};

export default WithdrawalList;
