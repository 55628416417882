import React, { useState, useEffect } from "react";
import { Input, message, Spin } from "antd";
import { Button, Image, Toast } from "antd-mobile";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import "./index.less";
import Withdrawal from "../../../components/Model/Withdrawal";
import Http from "../../../utils/Http";
import { setToken } from "../../../store/app";
import { store } from "../../../store";
import { log } from "@craco/craco/lib/logger";
//
import RealName from "../../../components/Model/RealName";
import AddBank from "../../../components/Model/AddBank";

const idImg = require("./assets/icon_my_id@2x.png");
const zuanshi = require("./assets/icon_main_chongzhi_zuanhsi@2x.png");
const shendou = require("./assets/icon_main_wallet_shendou@2x.png");
const alipay = require("./assets/icon_main_zhifu_alipay@2x.png");
const wxpay = require("./assets/icon_main_zhifu_wchat@2x.png");
const black = require("./assets/icon_nav_black@2x.png");
const Unionpay = require("./assets/Unionpay.png");

const Withdraw = (props) => {
  const withdrawalRef = React.useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  //获取路由地址
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [types, setTypes] = useState(2);

  /* 
  音色接口
  */
  const [userData, setUserData] = useState({});
  const [userSum, setUserSum] = useState({});
  const [userMoneyData, setUserMoneyData] = useState({});
  const [bankCharges, setBankCharges] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const realNameRef = React.useRef();
  const addBankRef = React.useRef();

  useEffect(() => {
    // const { App } = store.getState();
    // setUserData(App.userinfo);
    tixianSum();
    // 个人信息
    postUser();
    postConfig();
    // 提现金额
    userMoney();
  }, []);
  // 获取我的提现记录和可提现数额
  const tixianSum = async () => {
    try {
      const res = await Http.postForm("/data/api.auth.transfer/get");
      if (res.code === 1) {
        setUserSum(res.data);
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };
  // 个人信息
  const postUser = async () => {
    try {
      const res = await Http.postForm("/data/api.auth.center/get");
      if (res?.code === 1) {
        setUserData(res.data);
        // postGongmao();
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };
  // 获取提现金额
  const userMoney = async () => {
    try {
      const res = await Http.postForm("/data/api.auth.rebate/latestBalance");
      if (res?.code === 1) {
        setUserMoneyData(res.data);
        // postGongmao();
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };

  //提现手续费
  const postConfig = async () => {
    try {
      const res = await Http.postForm("/data/api.auth.transfer/config");
      if (res?.code === 1) {
        setBankCharges(res.data.charge);
        setMinAmount(res?.data.transfer.transfer_banks.minAmount);
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    }
  };
  // 选择账号
  const accountListUp = () => {
    // navigate("/accountList?type=" + types);
    // 未实名先实名
    if (userData.verify_status != 1) {
      message.warning("需先完成实名认证");
      realNameRef.current.show();
    } else {
      addBankRef.current.show(userData?.bank_card_account);
    }
  };
  // 提现确认
  const submit = async () => {
    if (loading) {
      return;
    }
    try {
      const res = await Http.postForm("/data/api.auth.transfer/add", {
        type: "transfer_banks",
        amount: amount,
        remark: "",
      });
      if (res.code === 1) {
        setAmount("");
        Toast.show("提现申请已提交");
        postUser();
        tixianSum();
      } else {
        Toast.show(res.info);
      }
    } catch (e) {
      Toast.show(e.info);
    } finally {
      setLoading(false);
    }
  };
  // gongmao

  return (
    <div className="money-page">
      <Withdrawal
        onSubmit={() => {
          submit();
        }}
        amount={amount}
        tixian={bankCharges}
        ref={withdrawalRef}
      />
      <RealName ref={realNameRef} onVerify={postUser} />{" "}
      <AddBank ref={addBankRef} onVerify={postUser} />
      <div className="money-header">
        <div className="money-left">
          <Image
            src={userData.headimg || ""}
            width={50}
            height={50}
            style={{ borderRadius: 35, marginRight: 8 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-start",
              height: 50,
            }}
          >
            <div>
              姓名：{userData.nickname || ""} ({userData.id || ""})
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ color: "#979797", fontSize: 13, lineHeight: 1 }}>
                手机号{userData.phone || ""}
              </div>
            </div>
          </div>
        </div>
        <Button
          style={{
            "--background-color": "#FFFFFF",
            "--border-color": "#FF850E",
          }}
          shape="rounded"
          size="small"
          danger
          onClick={() => {
            store.dispatch(setToken(null));
            message.success("退出成功");
            navigate("/");
          }}
        >
          <div style={{ color: "#FF850E", fontSize: 13, width: 54 }}>
            退出登录
          </div>
        </Button>
      </div>
      <div className="money-center">
        <div className="money-center-top">
          <div className="money-center-top-item">
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                marginTop: 17,
                marginBottom: 8,
              }}
            >
              <div style={{ fontSize: 16 }}>可提现余额</div>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "left",
                marginBottom: 16,
                color: "#000000",
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              {userMoneyData.available} 元
            </div>
          </div>
          <div
            className="money-center-top-item"
            onClick={() => {
              navigate("/withdrawalList", {
                state: { fanYong: 1 },
              });
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                marginTop: 17,
                marginBottom: 8,
              }}
            >
              <div style={{ fontSize: 16 }}>
                冻结收益
                <span style={{ marginLeft: 5 }}> > </span>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "left",
                marginBottom: 16,
                color: "#000000",
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              {userMoneyData.lock} 元
            </div>
          </div>
        </div>
        <div className="money-center-way">
          <div
            style={{
              paddingTop: 16,
              fontSize: 14,
              marginBottom: 16,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>到账渠道</div>
            <div
              onClick={() => {
                accountListUp(userData?.bank_card_account);
              }}
            >
              {userData?.bank_card_account == ""
                ? "添加银行卡 >"
                : "编辑银行卡 >"}
            </div>
          </div>

          <div className="money-way-list">
            <div
              className={"money-way-item2"}
              onClick={() => {
                setTypes(2);
              }}
            >
              <Image
                style={{ marginRight: 8 }}
                src={Unionpay}
                width={24}
                height={24}
              />
              <span style={{ fontSize: 16, fontWeight: 500, color: "#050303" }}>
                银行卡
              </span>
            </div>
          </div>
          {
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#F03350",
                paddingBottom: 12,
                fontSize: 13,
              }}
            >
              <span style={{ color: "#343537" }}>
                提现卡号: {userData?.bank_card_account || " 请添加提现银行卡"}
              </span>
            </div>
          }
        </div>
        <div className="moeny-center-num">
          <div style={{ paddingTop: 16, fontSize: 14, marginBottom: 16 }}>
            提现金额
          </div>
          <div className="money-center-input">
            <Input
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              bordered={false}
              placeholder="输入提现金额"
              prefix={
                <div style={{ fontSize: 16, color: "#343537", lineHeight: 1 }}>
                  ￥
                </div>
              }
              type="number"
              style={{ fontSize: 10, height: 48, borderRadius: 8 }}
            />
          </div>
        </div>
        <Button
          style={{
            height: 48,
            borderRadius: 8,
            backgroundColor: "#FF850E",
            marginTop: 22,
            marginBottom: 16,
          }}
          block
          onClick={() => {
            if (amount < Number(minAmount)) {
              Toast.show("提现最低金额" + minAmount);
              return;
            }
            if (amount > userSum?.total?.free) {
              Toast.show("输入金额超过可提现余额");
              return;
            }
            if (userData?.bank_card_account === "") {
              Toast.show("请先添加提现银行卡");
              return;
            }
            withdrawalRef.current.show();
          }}
        >
          <div
            style={{
              color: "#FFFFFF",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {loading && <Spin />}
            提现
          </div>
        </Button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
          onClick={() => {
            navigate("/withdrawalList", {
              state: { withdrawNum: userMoneyData.withdraw },
            });
          }}
        >
          <span style={{ marginRight: 4, color: "#FF850E" }}>提现明细</span>
          <Image src={black} width={14} height={14} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            paddingTop: 19,
            paddingBottom: 20,
          }}
        >
          <span style={{ color: "#343537", fontSize: 12 }}>提现需知:</span>
          {/* <div
            style={{ color: "#979797", fontSize: 12 }}
            dangerouslySetInnerHTML={{ __html: bankmoneymax }}
          /> */}
          <div>
            <div>
              <text>可提现余额：</text>
              <text>当前您可以提现的金额</text>
            </div>
            <div>
              <text>即将到账：</text>
              <text>交易中的金额，交易成功后可提现</text>
            </div>
            <div>
              <text>累计到账：</text>
              <text>累计交易成功的金额</text>
            </div>
            <div>
              <text>累计提现：</text>
              <text>累计提现成功的金额</text>
            </div>
            <div>
              <text>*注：所有金额币种均为人民币，单位为元，符号￥</text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
